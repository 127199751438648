// modifer: styles/jss/nextjs-material-kit-pro/pages/componentsSections/sectionCards.js
import {
  section,
  container,
  title,
  cardTitle,
  whiteColor,
  hexToRgb
} from 'styles/jss/original/base';

const styles = {
  section,
  container,
  cardTitle,
  cardTitleWhite: {
    '&, & a': {
      ...title,
      marginTop: '.625rem',
      marginBottom: '10px',
      minHeight: 'auto',
      color: whiteColor + ' !important'
    }
  },
  cardCategoryWhite: {
    marginTop: '10px',
    color: 'rgba(' + hexToRgb(whiteColor) + ', 0.7)'
  },
  cardDescriptionWhite: {
    color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    marginBottom: '20px',
    whiteSpace: 'pre-wrap'
  },
  cardBackground: {
    '@media (max-width: 640px)': {
      marginTop: '0',
      marginBottom: '30px'
    }
  },
  cardBody: {
    '@media (max-width: 640px)': {
      paddingTop: '10px',
      paddingBottom: '20px',
      minHeight: 'initial'
    }
  }
};

export default styles;
