import React from 'react';
import LazyLoad from 'react-lazyload';
import {
  compose,
  withStateHandlers,
  lifecycle,
  pure,
  StateHandler,
  StateHandlerMap
} from 'recompose';
import { Transition } from 'react-transition-group';

type ComponentProps = {
  children: React.ReactNode;
  imageUrl: string;
  height: number;
};

type Outter = {
  children: Todo;
  imageUrl: string;
  styles: Todo;
};

type State = {
  isLoaded: boolean;
};

interface Updaters extends StateHandlerMap<State> {
  onLoad: StateHandler<State>;
}

type Props = Outter & State & Updaters;

const LazyLoadInner = compose<Props, Outter>(
  withStateHandlers<State, Updaters, Outter>(
    {
      isLoaded: false
    },
    {
      onLoad: () => () => ({ isLoaded: true })
    }
  ),
  lifecycle<Props, Todo>({
    componentDidMount() {
      const image = new Image();
      image.onload = () => {
        this.props.onLoad();
      };
      image.src = this.props.imageUrl;
    }
  }),
  pure
)(({ children, imageUrl, isLoaded, styles }) => {
  const childStyle = isLoaded ? {} : { opacity: 0 };
  return (
    <Transition in={isLoaded} timeout={1000}>
      {() => (
        <div style={isLoaded ? styles.loadedOuter : styles.unLoadOuter}>
          {children(imageUrl, childStyle)}
        </div>
      )}
    </Transition>
  );
});

const LazyLoadBackgroundImage: React.FC<ComponentProps> = ({ children, imageUrl, height }) => {
  const styles = {
    placeholder: {
      backgroundColor: '#F3F3F3',
      height
    },
    unLoadOuter: {
      opacity: 0.05,
      height,
      backgroundColor: '#000'
    },
    loadedOuter: {
      opacity: 1,
      transition: 'opacity 1s ease-in'
    }
  };
  return (
    <LazyLoad height={height} offset={300} once placeholder={<div style={styles.placeholder} />}>
      <LazyLoadInner imageUrl={imageUrl} styles={styles}>
        {children}
      </LazyLoadInner>
    </LazyLoad>
  );
};

export default LazyLoadBackgroundImage;
