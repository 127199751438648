import React, { useMemo, useState, useEffect } from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import Card from '@/components/material-ui-kit/components/Card/Card';
import CardBody from '@/components/material-ui-kit/components/Card/CardBody';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import GridItem from '@/components/material-ui-kit/components/Grid/GridItem';
import styles from 'styles/jss/original/components/common/bottomBanner';
import LazyLoadBackgroundImage from '@/components/common/ui/LazyLoadBackgroundImage';
import { useMedia } from 'react-use';
import BottomBannerShopping from 'public/img/contents/bottom-banner-shopping.jpg';
import BottomBannerShoppingSp from 'public/img/contents/bottom-banner-shopping-sp.jpg';
import BottomBannerApp from 'public/img/contents/bottom-banner-app.jpg';
import BottomBannerAppSp from 'public/img/contents/bottom-banner-app-sp.jpg';

const IMAGE_HEIGHT_PC = 280;
const IMAGE_HEIGHT_SP = 230;

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  type?: 'shopping' | 'info';
};

const BottomBanner: React.FC<ComponentProps> = ({ type }) => {
  const classes = useStyles();
  const isSp = useMedia('(max-width: 640px)');
  const [imageHeight, setImageHeight] = useState(IMAGE_HEIGHT_PC);
  useEffect(() => {
    if (isSp) setImageHeight(IMAGE_HEIGHT_SP);
  }, [isSp]);
  const bannerList = useMemo(
    () => [
      {
        image: isSp ? BottomBannerShoppingSp : BottomBannerShopping,
        category: 'SHOPPING',
        title: 'ショッピング',
        description: '狭い空間でも活躍するアイテムを\n様々な切り口で紹介',
        link: '/shoppings',
        button: '詳細を見る',
        type: 'shopping'
      },
      {
        image: isSp ? BottomBannerAppSp : BottomBannerApp,
        category: 'INFO',
        title: 'アプリでもっと便利に',
        description: 'アプリなら検索やお気に入り登録など\n直感的に使える',
        link: 'https://apps.apple.com/jp/app/smalldish/id1561598079',
        button: 'App Storeからダウンロード',
        type: 'info'
      }
    ],
    [isSp]
  );
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {/* @ts-expect-error プロパティ「justify」は、タイプ「IntrinsicAttributes & Pick<InferProps<{ children.Requireable<ReactNodeLike>; className」に存在しません。*/}
        <GridContainer justify='center'>
          {bannerList
            .filter((item) => item.type !== type)
            .map((item) => {
              return (
                <GridItem key={item.title}>
                  <LazyLoadBackgroundImage height={imageHeight} imageUrl={item.image}>
                    {(imageUrl: string, style: Todo) => (
                      <Card
                        background
                        // @ts-expect-error タイプ '{ children: Element; background: true; style: any; className: string; }' は割り当てられません。
                        style={{ backgroundImage: `url(${imageUrl})`, ...style }}
                        className={classes.cardBackground}
                      >
                        <CardBody background className={classes.cardBody}>
                          <h6 className={classes.cardCategoryWhite}>{item.category}</h6>
                          <a>
                            <h3 className={classes.cardTitleWhite}>{item.title}</h3>
                          </a>
                          <p className={classes.cardDescriptionWhite}>{item.description}</p>
                          <Link href={item.link}>
                            <a>
                              {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
                              <Button round color='warning'>
                                {item.button}
                              </Button>
                            </a>
                          </Link>
                        </CardBody>
                      </Card>
                    )}
                  </LazyLoadBackgroundImage>
                </GridItem>
              );
            })}
        </GridContainer>
      </div>
    </div>
  );
};

export default BottomBanner;
